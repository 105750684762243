body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-lider {
  background-color: #c0ce00;
}

.lider {
  background-color: #c0ce00;
}

.h2, h2{
  margin-top: 1rem;
}

.footer {
  position: relative;
  bottom: 0;
  width:100%;
}

.logo {
  height: 50px;
}

.text-overflow {
  max-width: 15rem;
  white-space: nowrap;
  overflow: scroll;
}
.main-panel {
  background-color: rgba(238, 238, 231, 0.7);
}

.color-card {
  background-color: rgba(219, 245, 9, 0.3);
}